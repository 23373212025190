<script>
	let {
		src = null,
		alt = '',
		width = null,
		height = null,
		loading = 'lazy',
		noMeta = null,
		description = null,
		preload = null,
		class: className = '',
		descriptionSlot,
		children,
	} = $props();

	// function to sort image mimetype strings by preference avif > webp > png > jpeg
	// returns 1 if a is preferred, -1 if b is preferred, 0 if equal
	// (note: autopilot filled this in)
	function sortFormats(a, b) {
		const formats = ['avif', 'webp', 'png', 'jpeg'];
		const aIndex = formats.indexOf(a);
		const bIndex = formats.indexOf(b);
		if (aIndex > bIndex) {
			return 1;
		}
		if (aIndex < bIndex) {
			return -1;
		}
		return 0;
	}
</script>

{#if descriptionSlot || description}
	<div
		class={['!col-span-full mb-4 justify-items-center', className ?? ''].join(
			' ',
		)}
	>
		<picture class="image w-full max-md:!col-span-full [&_img]:mb-2">
			{@render children?.()}
			{#if src?.sources}
				{#if src?.media}
					{#each src?.media as [media, image]}
						{#each Object.entries(image?.sources).sort(sortFormats) as [format, srcset]}
							<source
								{media}
								{srcset}
								type={`image/${format}`}
								class="contents"
							/>
						{/each}
					{/each}
				{:else}
					{#each Object.entries(src?.sources).sort(sortFormats) as [format, srcset]}
						<source {srcset} type={`image/${format}`} class="contents" />
					{/each}
				{/if}
				<img
					src={src?.img?.src}
					{alt}
					{loading}
					width={width ?? src?.img?.w}
					height={height ?? src?.img?.h}
					class={className ?? null}
				/>
			{/if}
			{#if typeof src == 'string'}
				<img {src} {alt} {width} {height} class={className ?? null} {loading} />
			{/if}
		</picture>
		{#if descriptionSlot}{@render descriptionSlot()}{:else}
			<p class="font-bold">{description}</p>
		{/if}
	</div>
{:else}
	<picture class="picture w-full max-md:!col-span-full">
		{@render children?.()}
		{#if src?.sources}
			{#if src?.media}
				{#each src?.media as [media, image]}
					{#each Object.entries(image?.sources).sort(sortFormats) as [format, srcset]}
						<source
							{media}
							{srcset}
							type={`image/${format}`}
							class="contents"
						/>
					{/each}
				{/each}
			{:else}
				{#each Object.entries(src?.sources).sort(sortFormats) as [format, srcset]}
					<source {srcset} type={`image/${format}`} class="contents" />
				{/each}
			{/if}
			<img
				src={src?.img?.src}
				{alt}
				{loading}
				width={width ?? src?.img?.w}
				height={height ?? src?.img?.h}
				class={['image grid justify-items-center', className ?? ''].join(' ')}
			/>
		{/if}
		{#if typeof src == 'string'}
			<img
				{src}
				{alt}
				{width}
				{height}
				class={['image grid justify-items-center', className ?? ''].join(' ')}
				{loading}
			/>
		{/if}
	</picture>
{/if}
<svelte:head>
	{#if !noMeta && alt && src?.sources}
		<meta property="og:image" content={src?.img?.src} />
		<meta property="og:image:alt" content={alt} />
		<meta property="og:image:width" content={width ?? src?.img?.w} />
		<meta property="og:image:height" content={height ?? src?.img?.h} />
		<meta property="twitter:image" content={src?.img?.src} />
		<meta property="twitter:image:alt" content={alt} />
	{/if}
	{#if preload}
		{#if src?.sources}
			{#each Object.entries(src?.sources).sort(sortFormats) as [format, srcset]}
				{#if format === 'avif'}
					{#each srcset.match(/\S+\s\d+/g) ?? [] as src}
						<link
							rel="preload"
							type={`image/${format}`}
							href={src.split(' ')?.[0]}
							media={`(width < ${src.split(' ')?.[1]}px) and (not width >= ${src.split(' ')?.[1]}px)`}
							as="image"
						/>
					{/each}
				{/if}
			{/each}
		{/if}
	{/if}
</svelte:head>
